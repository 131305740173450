import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { MainComponent } from './components/main/main.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MyfilesComponent } from './components/myfiles/myfiles.component';
import { SinglepageuploadComponent } from './components/singlepageupload/singlepageupload.component';
import { PageloadComponent } from './components/pageload/pageload.component';
import { PageextractComponent} from './components/pageextract/pageextract.component';

const routes: Routes = [{
  path: 'login',
  component: LoginComponent
},
{
  path: 'main',
  component: MainComponent,
  canActivate: [AuthGuard]
},
{
  path: 'dashboard',
  component: DashboardComponent,
  canActivate: [AuthGuard],
  data: { breadcrumb: 'Dashboard' }
},
{
  path: 'myfiles',
  component: MyfilesComponent,
  canActivate: [AuthGuard],
  data: { breadcrumb: 'My Files' }
},
{
  path: 'singlepageupload',
  component: SinglepageuploadComponent,
  canActivate: [AuthGuard],
  data: { breadcrumb: 'Single Page' }
},
{
  path: 'pageupload',
  component: PageloadComponent,
  canActivate: [AuthGuard],
  data: { breadcrumb: 'Upload Page' }
},
{
  path: 'pageextract',
  component: PageextractComponent,
  canActivate: [AuthGuard],
  data: { breadcrumb: 'Extract Details' }
},
{
  path: '**',
  redirectTo: 'login'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
