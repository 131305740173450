<div class="top-header">
  <nav class="navbar navbar-expand-sm bg-light navbar-light">
    <a class="navbar-brand" href="#"><img src="../../../assets/images/app-logo.png" class="logo"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" aria-expanded="true">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="'/dashboard'" routerLinkActive="active" ><i class="fas fa-home"></i> Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" [routerLink]="'/myfiles'" routerLinkActive="active"><i class="fas fa-file-alt"></i> My Files</a>
        </li>   
      </ul>
      <div id="user-dropdown">
        <div class="user-display">
          <span class="user-name">{{userData.name}}</span>
          <!-- <span class="login-time">30 Apr 2020, 12.45 PM IST</span> -->
        </div>
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle icon-bg" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
             <img src="../../../assets/images/userAvatar.png" />
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" (click)="onLogout()">Log Out</a>
            </div>
          </li>
        </ul>
        </div>
    </div>      
  </nav>
</div>