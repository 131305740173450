import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { DocumentlistComponent } from './../documentlist/documentlist.component';
import { DashboardService } from '../../services/dashboard.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-myfiles',
  templateUrl: './myfiles.component.html',
  styleUrls: ['./myfiles.component.scss'],
  providers: [DialogService, MessageService]
})
export class MyfilesComponent implements OnInit, OnDestroy {
  breadCrumb: any;
  data: any;
  tableLoading: any;
  docListData: any;
  constructor(private route: ActivatedRoute,
              private dialogService: DialogService,
              private dashboardService: DashboardService,
              private messageService: MessageService) {
                this.docListData = [];
                this.tableLoading = true;
              }

  ngOnInit(): void {
    this.route.data.subscribe((item: any) => {
      this.breadCrumb = item.breadcrumb;
    });
    this.dashboardService.getRecentActivityList().subscribe((res: any) => {
      this.data = res;
      this.tableLoading = false;
    }, (error: any) => {
      this.messageService.add({severity: 'error', life: 5000, summary: 'Server Issue', detail: 'Problem in getting data from server'});
    });
  }
  onViewDocList(docType) {
    const ref = this.dialogService.open(DocumentlistComponent, {
      data: {
          type: docType
      },
      header: 'Documents List',
      width: '80%'
    });
  }
  onDownloadDocList(docType) {
    const fieldObj = {};
    this.dashboardService.getDocumentList(docType).subscribe((res: any) => {
      res.forEach((rec: any, index) => {
        // Create columnData from the first record fields data
        rec.fields.forEach((field: any) => {
        fieldObj[field.field_name] = field.field_value;
        });
        this.docListData.push({
          file_name: rec.file_name,
          vendor_name: rec.vendor_name,
          ...fieldObj
        });
      });
      this.dashboardService.downloadInExcel(this.docListData);
    });
  }
  ngOnDestroy() {
    this.messageService.clear();
  }
}
