import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor() { }
  getAPIGatewayURL() {
    // const apiURL = 'http://44.225.237.21/v1/api';
    // Production URL
    // const apiURL = 'https://textsight-backend.apps-evalueserve.com/v1/api';
    return `${environment.backendapi}`;
  }
  getSSOGatewayURL() {
    return `${environment.ssourl}`;
  }
  getSSOLoginURL() {
    return `${environment.ssologinurl}`;
  }
  getHttpOptions() {
    return {
      headers: new HttpHeaders({
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*',
      //  'Authorization': 'my-auth-token'
      })
    };
  }
  getMultipartHttpOptions() {
    return {
      headers: new HttpHeaders({
       'Content-Type': 'multipart/form-data',
       'Access-Control-Allow-Origin': '*',
      //  'Authorization': 'my-auth-token'
      })
    };
  }
}

