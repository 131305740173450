import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DashboardService } from '../../services/dashboard.service';
import { Table } from 'primeng/table';

import * as moment from 'moment';

@Component({
  selector: 'app-documentlist',
  templateUrl: './documentlist.component.html',
  styleUrls: ['./documentlist.component.scss']
})
export class DocumentlistComponent implements OnInit {
  @ViewChild('searchButton') searchButton: ElementRef;
  @ViewChild('dateFilter') dateFilter: any;
  @ViewChild('document_list') documentList: Table;
  docListData: any;
  columnData: any;
  sortColumnData: any;
  tableLoading: any;
  visible: boolean;
  filterDateRange: Date[];
  docListDataFiltered: any;
  searchKey: boolean;
  flag: boolean;
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.visible = false;
  }
  constructor(private dashboardService: DashboardService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) {
    this.columnData = [];
    this.sortColumnData = [];
    this.docListData = [];
    this.docListDataFiltered = [];
    this.tableLoading = true;
    this.searchKey = false;
   }

  ngOnInit(): void {
    this.visible = false;
    this.flag = false;
    const fieldObj = {};
    this.dashboardService.getDocumentList(this.config.data.type).subscribe((res: any) => {
      this.columnData.push({ field: 'file_name', header: 'File Name', type: 'string' },
        {field: 'vendor_name', header: 'User', type: 'string'},
        {field: 'modified_date', header: 'Date', type: 'date'});
      res.forEach((rec: any, index) => {
        // Create columnData from the first record fields data
        if (index === 0) {
          rec.fields.forEach((item: any) => {
            // const headerName = item.field_name.replace('_', ' ');
            this.columnData.push({
              field: item.field_name, header: item.field_name, type: 'string'
            });
          });
        }
        rec.fields.forEach((field: any) => {
        fieldObj[field.field_name] = field.field_value;
        });
        this.docListData.push({
          file_name: rec.file_name,
          vendor_name: rec.vendor_name,
          modified_date: rec.modified_date,
          ...fieldObj
        });
      });
      this.tableLoading = false;
      this.columnData.forEach((item: any) => {
        this.sortColumnData.push(item.field);
      });
    });
    this.filterDateRange = [new Date(), new Date()];
  }
  toggleDiv() {
    this.visible = !this.visible;
  }
  resetList() {
    this.flag = false;
    this.filterDateRange = [new Date(), new Date()];
    this.docListDataFiltered = [];
  }
  getdoc_list() {
    this.docListDataFiltered = [];
    this.flag = true;
    if (this.filterDateRange.length === 2) {
      this.docListData.forEach((item: any)  => {
        if ((new Date(`${item.modified_date}`) >=  this.filterDateRange[0]) &&
          (new Date(`${item.modified_date}`) <= new Date(moment(this.filterDateRange[1]).format('YYYY-MM-DD')))) {
            this.docListDataFiltered.push(item);
        }
      });
    } else {
      return false;
    }
  }
  onDatesRangeFilterSelected(selectedValue: Date) {
    if (this.filterDateRange[1]) {
      this.dateFilter.hideOverlay();
    }
  }
  searchTable(value) {
    if (value) {
      this.searchKey = true;
    } else {
      this.searchKey = false;
    }
  }
  onDownloadDocList() {
    console.log(this.docListDataFiltered.length);
    this.dashboardService.downloadInExcel(this.docListDataFiltered.length > 0 ?
      this.docListDataFiltered :
      this.searchKey ? this.documentList.filteredValue : this.docListData);
  }
}
