import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../services/authentication.service';
import { UtilityService } from './../../utils/utility.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  userData: any;
  constructor(private authenticationService: AuthenticationService, private utilityService: UtilityService) {
    authenticationService.getloggedinUser.subscribe(name => this.getName(name));
  }
  getName(name) {
    this.userData = name;
  }
  ngOnInit(): void {
    this.userData = this.authenticationService.currentUserValue;
  }
  onLogout() {
    this.authenticationService.onLogout();
    window.location.href = `${this.utilityService.getSSOGatewayURL()}logout`;
  }
}
