import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DashboardService } from '../../services/dashboard.service';
import { AuthenticationService } from './../../services/authentication.service';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
@Component({
  selector: 'app-pageload',
  templateUrl: './pageload.component.html',
  styleUrls: ['./pageload.component.scss'],
  providers: [ MessageService ]
})
export class PageloadComponent implements OnInit, OnDestroy {
  breadCrumb: string;
  routeData: any;
  displayExtractModal = false;
  displayCancelExtractModal = false;
  selectedCoordinates: any;
  croppedCoordinates: any;
  newFieldsData: any;
  trainedFieldsData: any;
  position: string;
  displayPosition: boolean;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  imageURL: string;
  edit: any;
  selectedTrainedFields: any;
  dummyData: any;
  checkRoute: boolean;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private messageServiceLoad: MessageService,
              private dashboardService: DashboardService,
              private authenticationService: AuthenticationService) {
    this.newFieldsData = [];
    this.trainedFieldsData = [];
    this.selectedTrainedFields = [];
    this.dummyData = [];
    this.checkRoute = true;
  }
  ngOnInit(): void {
    this.route.data.subscribe((item: any) => {
      this.breadCrumb = item.breadcrumb;
    });
    this.route.queryParamMap.subscribe(params => {
      this.edit = params.get('edit');
    });
    this.routeData = this.dashboardService.singlePageUploadData;
    console.log(this.routeData);
    if (this.routeData.selectedDocumentFields && this.routeData.selectedDocumentFields.length >= 0) {
      this.trainedFieldsData = [...this.routeData.selectedDocumentFields];
    }
    // If browser back then display newfields
    if (this.routeData.new_fields) {
      this.newFieldsData = this.routeData.new_fields;
    }
    if (this.edit === 'true') {
     this.newFieldsData = this.routeData.new_fields;
     this.selectedTrainedFields = [...this.routeData.selectedDocumentFields];
    }
  }
  gotoPreviousPage() {
    this.router.navigate(['singlepageupload']);
  }
  showCancelExtractDialog() {
    this.displayCancelExtractModal = !this.displayCancelExtractModal;
  }
  showExtractDialog(position: string) {
    this.position = position;
    this.displayExtractModal = true;
    const body = {
      new_fields : this.newFieldsData,
      existing_fields: this.edit === 'true' ? this.selectedTrainedFields : this.trainedFieldsData,
      doc_id: this.routeData.doc_id,
      doc_type: this.routeData.doc_type,
      user_id: 2,
      user_name: this.authenticationService.currentUserValue.name,
      instance_id: this.routeData.instance_id
    };
    this.dashboardService.getExtractedValues(body).subscribe((res: any) => {
      this.dashboardService.extractedValues = {
        data: res,
        instance_id: this.routeData.instance_id
      };
      // Below code req for edit mode
      this.dashboardService.singlePageUploadData = {
        ...this.dashboardService.singlePageUploadData,
        new_fields: this.newFieldsData
      };
      if (this.edit === 'true') {
        this.dashboardService.singlePageUploadData.selectedDocumentFields = this.selectedTrainedFields;
      } else {
        this.dashboardService.singlePageUploadData.selectedDocumentFields = this.trainedFieldsData;
      }
      if (this.checkRoute) {
        this.router.navigate(['pageextract']);
      }
    }, (error: any) => {
      this.messageServiceLoad.add({severity: 'error', life: 5000, summary: 'API Failed', detail: 'Request to API failed in backend'});
    });
  }
  imageLoaded() {
    this.showCropper = true;
  }
  cropperReady(sourceImageDimensions: Dimensions) {
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.selectedCoordinates = `X1: ${event.imagePosition.x1}, Y1:${event.imagePosition.y1}, X2:${event.imagePosition.x2}, Y2:${event.imagePosition.y2}` ;
    this.croppedCoordinates = [event.imagePosition.x1, event.imagePosition.y1, event.imagePosition.x2, event.imagePosition.y2];
  }
  loadImageFailed() {
  }
  addNewField(formData) {
    const fields = formData.form.value;
    console.log(this.routeData.trained_fields);
    console.log(this.routeData.trained_fields.indexOf(fields.fieldname));
    if (this.routeData.trained_fields.indexOf(fields.fieldname) > -1) {
      this.messageServiceLoad.add({severity: 'error', life: 4000,
        summary: 'Name Already Exists', detail: 'Please try with different field name'});
    } else {
      this.newFieldsData.push({
        field_name: fields.fieldname,
        coordinates: this.croppedCoordinates,
        selectedcoordinates: fields.fieldcoordinates
      });
      formData.reset();
    }
  }
  deleteAddedField(index) {
    this.newFieldsData.splice(index, 1);
  }
  selectTrainedFields() {

  }
  onClick() {
    this.checkRoute = false;
    this.router.navigate(['singlepageupload']);
  }
  ngOnDestroy() {
    this.messageServiceLoad.clear();
  }
}
