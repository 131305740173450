import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../../services/dashboard.service';
import { AuthenticationService } from './../../services/authentication.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-pageextract',
  templateUrl: './pageextract.component.html',
  styleUrls: ['./pageextract.component.scss'],
  providers: [MessageService]
})
export class PageextractComponent implements OnInit, OnDestroy {
  breadCrumb: string;
  docListData: any;
  columnData: any;
  disableSubmit: any;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private dashboardService: DashboardService,
              private authenticationService: AuthenticationService,
              private messageService: MessageService) {
    this.columnData = [];
    this.docListData = [];
    this.disableSubmit = false;
  }

  ngOnInit(): void {
    console.log(this.dashboardService.extractedValues);
    const fieldObj = {};
    this.route.data.subscribe((item: any) => {
      this.breadCrumb = item.breadcrumb;
    });
    this.columnData.push(
      { field: 'file_name', header: 'Name', type: 'string'},
      { field: 'vendor_name', header: 'Vendor', type: 'string'},
      { field: 'date', header: 'Modified Date', type: 'string'}
      );
    this.dashboardService.extractedValues.data.forEach((rec: any, index) => {
      if (index === 0) {
        rec.extracted_values.forEach((item: any) => {
          // const headerName = item.field_name.replace('_', ' ');
          this.columnData.push({
            field: item.field_name, header: item.field_name, type: 'string'
          });
        });
      }
      rec.extracted_values.forEach((field: any) => {
      fieldObj[field.field_name] = field.field_value;
    });
      this.docListData.push({
      file_name: rec.file_name,
      vendor_name: this.authenticationService.currentUserValue.name,
      date: rec.date,
        ...fieldObj
    });
  });
  }
  onDownloadDocList() {
  this.dashboardService.downloadInExcel(this.docListData);
  }
  onEditDocList() {
    this.router.navigate(['pageupload'], { queryParams: { edit: true } });
  }
  onSubmitClick() {
    this.disableSubmit = true;
    const body = {
      instance_id: this.dashboardService.extractedValues.instance_id
    };
    this.dashboardService.onSubmitClick(body).subscribe((res: any) => {
      if (res) {
        console.log(res);
        this.router.navigate(['dashboard']);
      }
    }, (error: any) => {
      console.log(error.error.message);
      this.messageService.add({severity: 'error', life: 5000, summary: 'Server Failed', detail: error.error.message});
      this.disableSubmit = false;
    });
  }
  onCancelClick() {
    this.router.navigate(['dashboard']);
  }
  ngOnDestroy() {
    this.messageService.clear();
  }
}
