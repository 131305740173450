<app-breadcrumb [data]="breadCrumb"></app-breadcrumb>
<!-- progressbar -->
<ul id="progressbar">
    <li id="define"><strong>Define</strong></li>
    <li id="load"><strong>Load</strong></li>
    <li class="active" id="save"><strong>Save</strong></li>
</ul>
<!-- progressbar -->
<div class="content">
    <div class="row flex-row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-heading">
                    <h5>Extracted Information</h5>
                    <div class="btn-group mb-3 sort-filter">           
                        <div class="btn-group">
                            <button class="btn btn-primary" (click)="onDownloadDocList()" pTooltip="Download" tooltipPosition="bottom">
                            <i class="fa fa-download"></i></button>  
                            <button class="btn btn-primary" (click)="onEditDocList()" pTooltip="Edit" tooltipPosition="bottom">
                            <i class="fas fa-edit"></i></button>           
                        </div>
                      </div>
                    <div class="input-group mb-3 main-search">
                        <input type="text" class="form-control" placeholder="Search">
                        <div class="input-group-append">
                          <button class="btn" type="submit"><i class="fa fa-search"></i></button>
                        </div>
                    </div> 
                </div>
                <div class="clearfix"></div>
                <p-table #document_list  [columns]="columnData" [value]="docListData" styleClass="ui-table-customers" [rowHover]="true"  [rows]="10" [showCurrentPageReport]="true"
                            [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [filterDelay]="0" [globalFilterFields]="columnData">
                    <ng-template pTemplate="header" let-column>
                    <tr>
                        <th *ngFor="let col of column" pSortableColumn='{{col.field}}'>{{col.header}}<p-sortIcon field='{{col.field}}'></p-sortIcon></th>
                        <!-- <th class="text-center">Actions</th> -->
                    </tr>                
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-columns="columns">
                                <tr class="ui-selectable-row">
                                    <td *ngFor="let col of columns">{{item[col.field]}}</td>
                                    <!-- <td class="text-center">
                                        <button class="btn btn_mrg" (click)="onEditDocList()" pTooltip="Edit" tooltipPosition="bottom"><i class="fas fa-edit"></i></button>
                                        <button class="btn btn_mrg" (click)="onDownloadDocList()" pTooltip="Download File" tooltipPosition="bottom"><i class="fa fa-download"></i></button>
                                    </td> -->
                                </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3" class="noDataFound">No Extracted Information Available.</td>
                                </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div class="row m-0">
        <div class="col-lg-12 p-0 text-right">
            <button type="button" class="secondaryButton" (click)="onCancelClick()">Cancel</button>
            <button type="button" class="purpleButton" (click)="onSubmitClick()" [disabled]="disableSubmit">Submit</button>            
        </div>
    </div>
</div>
<p-toast position="top-center"></p-toast>