<app-breadcrumb [data]="breadCrumb"></app-breadcrumb>
<!-- <p-fileUpload name="myfile[]" multiple="multiple" customUpload="true" (change)="fileChangeEvent($event)" (uploadHandler)="onUpload($event)"></p-fileUpload> -->
<!-- <input type="file" multiple (change)="fileChangeEvent($event)" />
<div>
    <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [imageURL]="imageURL"
        [maintainAspectRatio]="false"
        [aspectRatio]="16 / 9"
        [resizeToWidth]="128"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [alignImage]="'center'"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
</div> -->
<!-- progressbar -->
<ul id="progressbar">
    <li class="active" id="define"><strong>Define</strong></li>
    <li id="load"><strong>Load</strong></li>
    <li id="save"><strong>Save</strong></li>
</ul>
<!-- progressbar -->
<div class="content">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-heading">
                    <div class="row">
                        <div class="col-lg-6"><h5>Document Type <span class="docType">({{docType}})</span></h5></div>
                        <div class="col-lg-6 text-right"><button type="button" data-toggle="modal" data-target="#addDocumentTypeModal" class="btn btn-primary btn-select" (click)="onSelectDocType(0)" [disabled]="disableAddDocType">Add Document Type</button></div>
                    </div>                    
                    <div class="blueContainer">
                        <div class="row justify-content-center align-items-center m-0">
                            <div class="col-lg-4 p-3 text-center uploadFiles">
                                <i class="fas fa-cloud-upload-alt"></i>
                                <h3>Upload File(s)</h3>
                                <p>Browse your local files by clicking the button</p>
                                <button type="button" class="buttonCustom rounded hideContentInside pointer" [disabled]="disableBrowse">Browse<input type="file" #fileInput accept="image/*" (change)="fileChangeEvent($event)" [disabled]="disableBrowse" multiple/></button>
                            </div>
                            <div class="col-lg-8 leftBorder p-0">       
                                <!-- <ul id="fileThumbnails" *ngIf="!loaderImage" >
                                    <li>
                                        <img #uploadImage class="thumb-image"/>
                                        <div class="progressbar" *ngIf="progressBarVisible">
                                            <p-progressBar [value]="value"></p-progressBar>
                                        </div>
                                    </li>                
                                </ul> -->
                                <ul id="fileThumbnails" *ngIf="!loaderImage">
                                    <li *ngFor="let image of imageSrc">
                                        <img src={{image}} class="thumb-image"/>
                                        <div class="progressbar" *ngIf="progressBarVisible">
                                            <p-progressBar [value]="value"></p-progressBar>
                                        </div>
                                    </li>                
                                </ul> 
                            </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="row">
        <div class="col-lg-12 p-0">
            <div class="card">
                <div class="card-heading">                   
                        <h5>Select Document Fields <span class="docType">({{ selectedDocumentFields.length }}/{{trainedFields.length}} Selected)</span></h5>
                        <div class="beforeFileSection">
                            No fields selected here, please upload a valid file in the Document Type <i class="fas fa-arrow-up"></i> to select the document fields.
                            <div class="center">
                                <mat-spinner *ngIf="loaderFlag" style="margin:0 auto;" diameter="40" mode="indeterminate"></mat-spinner>
                            </div>
                        </div>
                   <div class="afterFileSection">
                    <div class="row">
                        <div class="col-lg-2 mb-2" *ngFor="let item of trainedFields; let i = index">
                            <p-checkbox (onChange)="selectCheckBox()" name="traineddocumentfields" label="{{item}}" value="{{item}}" [(ngModel)]="selectedDocumentFields" inputId="ny" *ngIf="!loaderFlag"></p-checkbox>
                        </div>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 p-0 text-right">
            <button type="button" (click)="onCancelClick()"class="secondaryButton">Cancel</button>
            <button type="button" (click)="onNextClick()" [disabled]="(docType=== null || docType === undefined)" class="purpleButton">Next</button>            
            </div>
            </div>
</div>




<!-- Add Document Type modal -->
<div class="modal fade" id="addDocumentTypeModal" tabindex="-1" role="dialog" aria-labelledby="addDocumentTypeModal" aria-hidden="true">
    <form #doctypeForm="ngForm" class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">Add Document Type</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-4">
                        <div class="form-group">
                            <label for="doctype">Choose a DocType:</label>
                            <select #predoctypes class="form-control" name="doctype" (change)="onSelectDocType($event.target.value)">
                            <option *ngFor="let item of docTypePreviewData; let i = index" [attr.value]="i" [attr.selected]="i == 0 ? true : null">{{item.doc_type}}</option>
                            </select>
                        </div>             
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="selected_file">Selected File</label>
                            <img src={{previewImage}} class="preview-image"/>                
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="selected_file">Uploaded File</label>
                            <img src={{imageSrc[0]}} class="preview-image"/>                
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label for="Document Type">Add New Document Type Name</label>
                            <input #doctypename  type="text" class="form-control" name="doctype" placeholder="Enter text"  (keydown.space)="$event.preventDefault();" required [(ngModel)]="newDocType"> 
                        </div>
                    </div>
                </div>
            </div>
            
            
            
        </div>
        <div class="modal-footer">
          <button type="submit" data-dismiss="modal" class="buttonCustom rounded"  [disabled]="!doctypeForm.form.valid" (click)="createNewDocType(doctypename.value)">Submit</button>
        </div>
      </div>
    </form>
  </div>
  <p-toast position="top-center"></p-toast>
