import { Injectable, Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilityService } from '../utils/utility.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  @Output() getloggedinUser: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient, private utilityService: UtilityService) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue() {
    return this.currentUserSubject.value;
}
  ssoAuthenticate(authID) {
    return this.http.get(`${this.utilityService.getSSOGatewayURL()}api/verify/?app=textsight&auth_id=${authID}`);
  }
  onLogin(user) {
    if (user) {
      sessionStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
      this.getloggedinUser.emit(user);
    }
  }
  onLogout() {
    // remove user from local storage to log user out
   sessionStorage.removeItem('currentUser');
   this.currentUserSubject.next(null);
  }
}

