import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import {TooltipModule} from 'primeng/tooltip';
import {DialogModule} from 'primeng/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ProgressBarModule} from 'primeng/progressbar';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastModule } from 'primeng/toast';

import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { MyfilesComponent } from './components/myfiles/myfiles.component';
import { SinglepageuploadComponent } from './components/singlepageupload/singlepageupload.component';
import { DocumentlistComponent } from './components/documentlist/documentlist.component';
import { PageloadComponent } from './components/pageload/pageload.component';
import { PageextractComponent } from './components/pageextract/pageextract.component';

import { DashboardService } from './services/dashboard.service';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    NavbarComponent,
    BreadcrumbComponent,
    DashboardComponent,
    FooterComponent,
    MyfilesComponent,
    SinglepageuploadComponent,
    DocumentlistComponent,
    PageloadComponent,
    PageextractComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    FileUploadModule,
    ImageCropperModule,
    DynamicDialogModule,
    NgScrollbarModule,
    TooltipModule,
    TabViewModule,
    AccordionModule,
    CalendarModule,
    DropdownModule,
    CheckboxModule,
    DialogModule,
    MatProgressSpinnerModule,
    ProgressBarModule,
    DialogModule,
    ToastModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
