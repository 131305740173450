<app-breadcrumb [data]="breadCrumb"></app-breadcrumb>
<div class="clearfix"></div>
  <div class="content">
    <div class="row">
      <div class="col-lg-6">
        <div class="card minHeight430">
            <div class="card-heading">
                <h5>Recent Activity</h5>
                <div class="input-group mb-3 main-search">
                  <input type="text" pInputText class="form-control" (input)="instance_list.filterGlobal($event.target.value, 'contains')" placeholder="Search">
                  <div class="input-group-append">
                    <button class="btn" type="submit"><i class="fa fa-search"></i></button>
                  </div>
                </div>
            </div> 
          <p-table #instance_list [value]="data" [responsive]="true" [loading]="tableLoading" [rowHover]="true"  [rows]="9" [showCurrentPageReport]="true"
            [paginator]="!tableLoading" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [filterDelay]="0" [globalFilterFields]="['doc_type','vendor_name','timestamp','doc_number']">
            <ng-template pTemplate="header">
                <tr>
                    <th width="20%" pSortableColumn="doc_type">Name<p-sortIcon field="doc_type"></p-sortIcon></th>
                    <th width="20%" pSortableColumn="vendor_name">Owner <p-sortIcon field="vendor_name"></p-sortIcon></th>
                    <th width="25%" pSortableColumn="timestamp">Modified Date <p-sortIcon field="timestamp"></p-sortIcon></th>
                    <th width="16%" pSortableColumn="doc_number">File Count <p-sortIcon field="doc_number"></p-sortIcon></th>
                    <th width="14%" class="text-center">Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr class="ui-selectable-row">
                    <td><span class="ui-column-title">Name</span>{{item.doc_type}}</td>
                    <td><span class="ui-column-title">Owner</span>{{item.vendor_name}}</td>
                    <td><span class="ui-column-title">Modified Date</span>{{item.modified_date}} </td>
                    <td><span class="ui-column-title">File Count</span>{{item.count}}</td> 
                    <td class="text-center">
                      <button class="btn btn_mrg" (click)="onViewDocList(item.doc_type)" pTooltip="View Details" tooltipPosition="bottom"><i class="fa fa-eye"></i></button>
                      <button class="btn btn_mrg" (click)="onDownloadDocList(item.doc_type)" pTooltip="Download" tooltipPosition="bottom"><i class="fa fa-download"></i></button>
                      <!-- <button class="btn btn_mrg" pTooltip="Delete" tooltipPosition="bottom"><i class="fa fa-trash"></i></button> -->
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5" class="noDataFound">No Matching Records Found.</td>
                </tr>
            </ng-template>
        </p-table>          
        </div>
      </div>  
        <div class="col-lg-6">
            <div class="card minHeight430">
            <div class="card-heading">
                <h5>Select Document Type & Format</h5>
            </div> 
                <p>Select any one of the document format to upload your documents.</p>
            <div class="row">
                <div class="col-lg-6">
                <div class="select-this">                    
                    <span class="circle singleFile"></span>
                    <h5>Single Page</h5>
                    <p></p>
                    <button type="button" class="btn btn-primary btn-select" (click)="onSinglePageClick()">Select</button>
                </div>
                </div>               
                <!-- <div class="col-lg-6">
                <div [ngClass]="activeFileSelection ? 'select-this2' : 'select-this'">                   
                    <span class="circle multiFile1"></span>
                    <h5>Multiple Pages</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipis elit, sed do eiusmod temporary.</p>
                    <button type="button" (click)="changeParentClass()" class="btn btn-primary btn-select">Select</button>
                </div>
                </div> -->
            </div>
            <!-- <div class="card-bottom">         
                <button type="button" class="btn btn-default btn-continue btn-disabled">Continue</button>
            </div> -->
            </div>
        </div> 
    </div>
  </div>