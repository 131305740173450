<app-breadcrumb [data]="breadCrumb"></app-breadcrumb>
<!-- progressbar -->
<ul id="progressbar">
    <li id="define"><strong>Define</strong></li>
    <li class="active" id="load"><strong>Load</strong></li>
    <li id="save"><strong>Save</strong></li>
</ul>
<!-- progressbar -->

<div class="content">
    <div class="row flex-row">
        <div class="col-lg-3 pl-0 pr-0">
            <div class="col-lg-12">
                <div class="card LeftcardPadding">
                    <div class="leftPageAccordion">
                        <p-accordion>
                            <p-accordionTab class="position-relative" [selected]="true"
                                header="Selected Document Fields">
                                <div class="position-absolute selectedDocumentsFieldsNumber">({{edit === 'false' ? trainedFieldsData.length : selectedTrainedFields.length}})</div>
                                <ng-scrollbar class="leftScroll" *ngIf="edit === 'false'">
                                    <ul class="selectedDocumentFields" *ngFor="let item of routeData?.trained_fields">
                                        <!-- <li>{{item}}</li> -->
                                        <li class="edit"><p-checkbox  [(ngModel)]="trainedFieldsData" name="trainedFieldsData" label="{{item}}" value="{{item}}" inputId="ny"></p-checkbox></li>
                                    </ul>
                                </ng-scrollbar>
                                <ng-scrollbar class="leftScroll" *ngIf="edit === 'true'">
                                    <ul class="selectedDocumentFields" *ngFor="let item of routeData?.trained_fields">
                                        <li class="edit"><p-checkbox  (onChange)="selectTrainedFields()" [(ngModel)]="selectedTrainedFields" name="traineddocumentfields" label="{{item}}" value="{{item}}" inputId="nye"></p-checkbox></li>
                                    </ul>
                                </ng-scrollbar>                                
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 mt-2">
                <div class="card LeftcardPadding">
                    <div class="leftPageAccordion">
                        <p-accordion>
                            <p-accordionTab class="position-relative" [selected]="true" header="Additional Fields">
                                <div class="position-absolute additionalFieldsNumber">({{newFieldsData?.length}})</div>
                                <div class="addNewFieldsSection">
                                    <form #addFieldForm="ngForm" class="row m-0" (ngSubmit)="addNewField(addFieldForm)">
                                        <div class="col-lg-8 p-0 pr-1">
                                            <input type="text" name="fieldname" placeholder="Field Name" class="enterNewField" (keydown.space)="$event.preventDefault();" required ngModel/>
                                        </div>
                                        <div class="col-lg-8 p-0 pr-1">
                                            <input type="text" name="fieldcoordinates" placeholder="Field Coordinates" class="enterNewField" required [(ngModel)]="selectedCoordinates" />
                                        </div>
                                        <div class="col-lg-4 p-0 text-right">
                                            <button type="submit" class="addFieldSmallButton" [disabled]="!addFieldForm.form.valid"><i class="fas fa-plus"></i> Add Field</button>
                                        </div>
                                    </form>
                                </div>

                                <div class="existingFieldsSection">
                                    <div class="form-group" *ngFor="let item of newFieldsData; let i=index">
                                        <label class="float-left" for="cityName">{{item.field_name}}</label>
                                        <button class="deleteButton float-right" (click)="deleteAddedField(i)">Delete</button>
                                        <input type="text" class="form-control" placeholder="Enter your value" [(ngModel)]="item.selectedcoordinates" id="cityName">
                                    </div>
                                </div>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9">
            <div class="card">
                <div class="card-heading">
                    <h5>Modify File <span class="docType"></span></h5>
                    <div class="beforeFileSection">
                         No fields selected here, please upload a valid file in the Document
                        Type <i class="fas fa-arrow-up"></i> to select the document fields.
                    </div>
                    <div class="blueContainer" id="rightImageScrollbarSection">
                        <ng-scrollbar class="rightImageScroll" track="vertical">
                            <div id="filePreviewSection">
                            <image-cropper
                                    [imageBase64]="routeData?.filemetadata[0]"
                                    [imageURL]="imageURL"
                                    [maintainAspectRatio]="false"
                                    [aspectRatio]="16 / 9"
                                    [resizeToWidth]="128"
                                    [onlyScaleDown]="true"
                                    [roundCropper]="false"
                                    [canvasRotation]="canvasRotation"
                                    [transform]="transform"
                                    [alignImage]="'center'"
                                    format="png"
                                    (imageCropped)="imageCropped($event)"
                                    (imageLoaded)="imageLoaded()"
                                    (cropperReady)="cropperReady($event)"
                                    (loadImageFailed)="loadImageFailed()"></image-cropper>
                            </div>
                        </ng-scrollbar>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 text-right">
            <button type="button" (click)="showCancelExtractDialog()" class="secondaryButton">Cancel</button>
            <button type="button" (click)="showExtractDialog('top')" 
            [disabled]="edit === 'false' ? 
                (trainedFieldsData.length === 0 ? newFieldsData?.length === 0 : false)
                : (selectedTrainedFields.length === 0 ? newFieldsData?.length === 0 : false)" 
            class="purpleButton">Extract</button>
        </div>
    </div>
</div>



<p-dialog id="extractModal" header="Extract" [(visible)]="displayExtractModal" [modal]="true" [style]="{width: '60vw'}" [baseZIndex]="10000"
[draggable]="false" [resizable]="false" (onHide)="showCancelExtractDialog()">
<div class="blueContainer mt-0 p-0 overflow-hidden">
    <div class="extractProcess">        
    </div>
    <div class="circleContainer">
        <div class="circle2"><div class="circle"></div></div>
    </div>
</div>
</p-dialog>

<p-dialog id="cancelExtractModal" class="extramodel" header="Loss of Data" [(visible)]="displayCancelExtractModal" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
[draggable]="false" [resizable]="false">
<div class="mt-0 p-0 text-center">
    <h2>Stop Instance?</h2>
    <div class="stopExtractionText">90% of the extraction completed. Do you really want to cancel this instance? This process cannot be undone.</div>
    <div class="row m-0 mt-3 mb-3">
        <div class="col-lg-12 text-center">
            <button type="button" class="secondaryButton" (click)="onClick()">Yes, Cancel</button>
            <button type="button" class="purpleButton" (click)="showExtractDialog('top')">No, Don't Cancel</button>
        </div>
    </div>
</div>
</p-dialog>
<p-toast position="top-center"></p-toast>
