import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '../utils/utility.service';
import { AuthenticationService } from '../services/authentication.service';
import * as xlsx from 'xlsx';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  singlePageUploadData: any;
  extractedValues: any;
  constructor(private http: HttpClient, private utilityService: UtilityService, private authenticationService: AuthenticationService) {
  }
  getRecentActivityList() {
    const body = {
      user_name: this.authenticationService.currentUserValue.name,
      role: this.authenticationService.currentUserValue.role
    };
    console.log(this.authenticationService.currentUserValue);
    return this.http.post(`${this.utilityService.getAPIGatewayURL()}/all_records`, body, this.utilityService.getHttpOptions());
  }
  getDocumentList(type) {
    const body = {
      user_name: this.authenticationService.currentUserValue.name,
      role: this.authenticationService.currentUserValue.role,
      user_id: 4,
      doc_type: type
    };
    return this.http.post(`${this.utilityService.getAPIGatewayURL()}/child_table`, body, this.utilityService.getHttpOptions());
  }
  UploadFile(body) {
    return this.http.post(`${this.utilityService.getAPIGatewayURL()}/upload_multiple_files`, body);
  }
  createNewDocType(body) {
    return this.http.post(`${this.utilityService.getAPIGatewayURL()}/new_instance_name`, body, this.utilityService.getHttpOptions());
  }
  getExtractedValues(body) {
    return this.http.post(`${this.utilityService.getAPIGatewayURL()}/all_field_Extract`, body, this.utilityService.getHttpOptions());
  }
  onSubmitClick(body) {
    return this.http.post(`${this.utilityService.getAPIGatewayURL()}/submit`, body, this.utilityService.getHttpOptions());
  }
  downloadInExcel(doc) {
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(doc);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'doc_list.xlsx');
  }
}
