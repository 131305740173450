import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DashboardService } from '../../services/dashboard.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-singlepageupload',
  templateUrl: './singlepageupload.component.html',
  styleUrls: ['./singlepageupload.component.scss'],
  providers: [MessageService]
})
export class SinglepageuploadComponent implements OnInit, OnDestroy {
@ViewChild('uploadImage') uploadImage: ElementRef;
@ViewChild('fileInput') fileInput: ElementRef;
  breadCrumb: string;
  docType: string;
  imageSrc: any;
  trainedFields: any;
  selectedDocumentFields: any;
  docTypePreviewData: any;
  previewImage: any;
  newDocType: any;
  disableAddDocType: boolean;
  loaderFlag: boolean;
  loaderImage: boolean;
  progressBarVisible: boolean;
  disableBrowse: boolean;

  value: number;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private dashboardService: DashboardService,
              private authenticationService: AuthenticationService) {
    this.docType = undefined;
    this.imageSrc = [];
    this.selectedDocumentFields = [];
    this.trainedFields = [];
    this.docTypePreviewData = [];
  }
  ngOnInit(): void {
    this.value = 0;
    this.progressBarVisible = false;
    const interval = setInterval(() => {
      this.value = this.value + Math.floor(Math.random() * 10) + 1;
      if (this.value >= 100) {
              this.value = 100;
            //  this.progressBarVisible = false;
              clearInterval(interval);
      }
    }, 2000);
    this.disableBrowse = false;
    this.disableAddDocType = true;
    this.loaderFlag = false;
    this.loaderImage = true;
    this.route.data.subscribe((item: any) => {
      this.breadCrumb = item.breadcrumb;
    });
  }
  fileChangeEvent(event) {
    const typeCheck = [];
    [...this.fileInput.nativeElement.files].forEach(file => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        this.imageSrc.push(fileReader.result);
      };
      file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' ?
      typeCheck.push(true) : typeCheck.push(false);
    });
    if (typeCheck.indexOf(false) > -1) {
      this.messageService.add({severity: 'error', life: 5000, summary: 'Upload Failed',
        detail: 'Please upload either JPEG/PNG/JPG files only'});
      return false;
    } else {
      this.disableBrowse = true;
      this.progressBarVisible = true;
      this.loaderFlag = true;
      this.dashboardService.singlePageUploadData = {
        filemetadata: this.imageSrc
      };
      this.loaderImage = false;
      const formData: FormData = new FormData();
      console.log(event.target.files);
      [...event.target.files].forEach(file => {
        formData.append('file', file);
      });
      formData.append('user_name', this.authenticationService.currentUserValue.name);
      formData.append('role', this.authenticationService.currentUserValue.role);
      this.dashboardService.UploadFile(formData).subscribe((res: any) => {
          this.docType = res.doc_type;
          if (res.doc_type === null) {
            this.disableAddDocType = false;
            this.docTypePreviewData = [...res.doc_details];
            this.messageService.add({severity: 'info', life: 5000, summary: 'Add New Document Type',
            detail: 'Create New Document Type by clicking on Add Document Type'});
          }
          res.all_extract.forEach((item: any) => {
            this.trainedFields.push(item.field_name);
          });
          this.dashboardService.singlePageUploadData = {
            ...this.dashboardService.singlePageUploadData,
            doc_type: res.doc_type,
            doc_id: res.doc_id,
            instance_id: res.instance_id,
            file_name: res.file_name,
            trained_fields: this.trainedFields
          };
          console.log(this.dashboardService.singlePageUploadData);
               // this.progressBarVisible = false;
          this.value = 100;
          this.loaderFlag = false;
        }, (error: any) => {
          this.loaderFlag = false;
          this.loaderImage = true;
          this.disableBrowse = false;
          this.getApiFailure();
        });
    }
    // const file = event.target.files[0];
    // if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' ) {
    // this.disableBrowse = true;
    // this.progressBarVisible = true;
    // this.loaderFlag = true;
    // const fileReader = new FileReader();
    // fileReader.onload = (e) => {
    //   this.uploadImage.nativeElement.src = fileReader.result;
    //   this.dashboardService.singlePageUploadData = {
    //     filemetadata: fileReader.result
    //   };
    // };
    // this.loaderImage = false;
    // fileReader.readAsDataURL(file);
    // const formData: FormData = new FormData();
    // formData.append('file', file);
    // formData.append('user_name', 'admin');
    // console.log(file);
    // this.dashboardService.UploadFile(formData).subscribe((res: any) => {
    //   this.docType = res.doc_type;
    //   if (res.doc_type === null) {
    //     this.disableAddDocType = false;
    //     this.messageService.add({severity: 'info', life: 5000, summary: 'Add New Document Type',
    //     detail: 'Create new document type by clicking on add new document type'});
    //   }
    //   res.all_extract.forEach((item: any) => {
    //     this.trainedFields.push(item.field_name);
    //   });
    //   this.dashboardService.singlePageUploadData = {
    //     ...this.dashboardService.singlePageUploadData,
    //     doc_type: res.doc_type,
    //     doc_id: res.doc_id,
    //     instance_id: res.instance_id,
    //     trained_fields: this.trainedFields
    //   };
    //   console.log(this.dashboardService.singlePageUploadData);
    //        // this.progressBarVisible = false;
    //   this.value = 100;
    //   this.loaderFlag = false;

    // }, (error: any) => {
    //   this.loaderFlag = false;
    //   this.loaderImage = true;
    //   this.disableBrowse = false;
    //   this.getApiFailure();
    // });
    // } else {
    //   this.messageService.add({severity: 'error', life: 5000, summary: 'Upload Failed',
    //     detail: 'Please upload either JPEG/PNG/JPG files only'});
    //   return false;
    // }
  }
  selectCheckBox() {
    this.dashboardService.singlePageUploadData = {
      ...this.dashboardService.singlePageUploadData,
      selectedDocumentFields: this.selectedDocumentFields};
  }
  onSelectDocType(index) {
    this.previewImage = `data:image/jpeg;base64,${this.docTypePreviewData[index].image_data}`;
    this.newDocType = this.docTypePreviewData[index].doc_type;
    this.trainedFields = [...this.docTypePreviewData[index].fields];
  }
  createNewDocType(formData) {
    const testDocType = [];
    this.docTypePreviewData.forEach((item: any) => {
      testDocType.push(item.doc_type);
    });
    if (testDocType.indexOf(formData) === -1) {
      this.trainedFields = [];
    }
    this.docType = formData;
    const body = {
      doc_type: formData,
      instance_id: this.dashboardService.singlePageUploadData.instance_id,
      file_name: this.dashboardService.singlePageUploadData.file_name
    };
    this.dashboardService.createNewDocType(body).subscribe((res: any) => {
      if (res.doc_id !== 'null') {
        this.disableAddDocType = true;
        this.dashboardService.singlePageUploadData.doc_type = formData;
        this.dashboardService.singlePageUploadData.trained_fields = this.trainedFields;
        this.dashboardService.singlePageUploadData.doc_id = res.doc_id;
        this.messageService.add({severity: 'info', life: 5000, summary: 'Successfully Added',
        detail: 'New Document Type created successfully'});
      } else {
        this.messageService.add({severity: 'warn', life: 5000, summary: 'Adding Failed',
        detail: 'Please try again by clicking add new doc type'});
      }
    }, (error: any) => {
      this.getApiFailure();
    });
  }
  onNextClick() {
    this.router.navigate(['pageupload'], { queryParams: { edit: false } });
  }
  onCancelClick() {
    this.router.navigate(['dashboard']);
  }
  ngOnDestroy() {
    this.messageService.clear();
  }
  getApiFailure() {
    return this.messageService.add({severity: 'error', life: 5000, summary: 'API Failed', detail: 'Request to API failed in backend'});
  }
}
