import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'textsight';
  isLoginPage: boolean;
  redirectUrl: string;
  constructor(private router: Router) {
    this.isLoginPage = false;
   }

  ngOnInit(): void {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (e.url.match(/login/g) !== null) {
          this.isLoginPage = e.url.match(/login/g).indexOf('/login') >= -1;
          this.redirectUrl = e.urlAfterRedirects;
        } else if (e.url === '/') {
          this.isLoginPage = true;
        } else {
          this.isLoginPage = false;
        }
    });
  }

}
