import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from './../../services/authentication.service';
import { UtilityService } from './../../utils/utility.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  returnUrl: any;
  authenticatedMessage: string;
  constructor(private activatedRoute: ActivatedRoute,
              private route: Router,
              private authenticationService: AuthenticationService,
              private utilityService: UtilityService) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((params: any) => {
      if (params.get('auth_id') !== null) {
        this.verifyAuthentication(params.get('auth_id'));
      } else {
        if (window.location.hash === '#/login' || window.location.hash === '/') {
          window.location.href = `${this.utilityService.getSSOLoginURL()}`;
        }
      }
    });
    // To run on localhost enable this
    // this.authenticationService.onLogout();
    // this.authenticationService.onLogin(
    //   {message: 'Authenticated', role: 'Admin', name: 'Saripalli Devinath', email: 'Saripalli.Devinath@evalueserve.com'});
    // this.route.navigate(['dashboard']);
  }
  verifyAuthentication(authId) {
     this.authenticationService.ssoAuthenticate(authId).subscribe((res: any) => {
      this.authenticatedMessage = res.message;
      if (this.authenticatedMessage !== 'Not Authenticated') {
        this.authenticationService.onLogout();
        this.authenticationService.onLogin(res);
        this.route.navigate(['dashboard']);
      }
    }, (error: any) => {
      this.authenticatedMessage = 'Not Authenticated';
    });
  }
}
