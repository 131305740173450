// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //backendapi: 'https://textsight-backend.apps-evalueserve.com/v1/api',
 // backendapi: 'http://44.225.237.21:81/v1/api',
  //ssourl: 'https://apps-evalueserve.com/',
  //ssologinurl: 'https://apps-evalueserve.com/login/external/dev-textsight'
  backendapi: 'https://textsight-api.apps-evalueserve.com/v1/api',
  ssourl: 'https://apps-evalueserve.com/',
  ssologinurl: 'https://apps-evalueserve.com/login/external/prod-textsight'


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
