<div class="card-heading">
    <!-- <button pTooltip="Download File" tooltipPosition="bottom"><i class="fas fa-file-pdf"></i></button> -->
                <div class="btn-group mb-3 sort-filter">           
                    <div class="btn-group" (click)="$event.stopPropagation()">
                      <button type="button" class="btn btn-primary" (click)="toggleDiv()">
                        <i class="fa fa-calendar-alt"></i></button>
                        <div class="toggleDiv" *ngIf="visible" >
                            <label>Select Date Range</label>
                            <p-calendar  dateFormat="dd-M-yy" #dateFilter [(ngModel)]="filterDateRange" (onSelect)="onDatesRangeFilterSelected($event)" selectionMode="range"  [readonlyInput]="true"></p-calendar>
                            <div class="float-left mt-2 p-0">
                                <button (click)="resetList()"class="secondaryButton">Reset</button>
                                <button (click)="getdoc_list()" class="mainButton">Apply</button>
                            </div>
                        </div>
                    </div>
                    <div class="btn-group">
                        <button pTooltip="Download" class="btn btn-primary" tooltipPosition="bottom" (click)="onDownloadDocList()">
                        <i class="fa fa-download"></i></button>            
                    </div>          
                  </div>
                <div class="input-group mb-3 main-search">
                    <input type="text" #searchInput pInputText class="form-control" (keyup)="searchTable($event.target.value)" (input)="document_list.filterGlobal($event.target.value, 'contains')" placeholder="Search">
                    <div class="input-group-append">
                      <button #searchButton class="btn" type="submit"><i class="fa fa-search"></i></button>
                    </div>
                </div>
</div>
<div class="clearfix"></div>
<p-table #document_list  [columns]="columnData" [value]=" flag? docListDataFiltered :docListData" styleClass="ui-table-customers" [rowHover]="true"  
    [loading]="tableLoading" [paginator]="!tableLoading" [rows]="10" [showCurrentPageReport]="true" [totalRecords]="flag? docListDataFiltered.length :docListData.length"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0" 
    >
    
     <ng-template pTemplate="header" let-column>
    <tr>
        <th *ngFor="let col of column" pSortableColumn='{{col.field}}'>{{col.header}}<p-sortIcon field='{{col.field}}'></p-sortIcon></th>
    </tr>                
    </ng-template> 
     <ng-template pTemplate="body" let-item let-columns="columns">
                <tr class="ui-selectable-row">
                    <td *ngFor="let col of columns">{{item[col.field]}}</td>
                </tr>
    </ng-template> 
    <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3" class="noDataFound">No Matching Records Found.</td>
                </tr>
    </ng-template>
</p-table>
