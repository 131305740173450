<app-breadcrumb [data]="breadCrumb"></app-breadcrumb>
<div class="content">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-heading">
            <h5>My Files List</h5>
            <!-- <div class="btn-group mb-3 sort-filter">            
              <div class="btn-group">

                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                  <i class="fa fa-sort"></i> Sort</button>               
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">Date</a>
                  <a class="dropdown-item" href="#">Amount</a>
                </div>
              </div> 
              <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                  <i class="fa fa-filter"></i> Filter</button>                 
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">Date</a>
                  <a class="dropdown-item" href="#">Amount</a>
                </div>
              </div>           
            </div> -->
            <div class="input-group mb-3 main-search">
              <input type="text" pInputText class="form-control" (input)="instance_list.filterGlobal($event.target.value, 'contains')" placeholder="Search">
              <div class="input-group-append">
                <button class="btn" type="submit"><i class="fa fa-search"></i></button>
              </div>
            </div>          
          </div>    
          <p-table #instance_list [value]="data" [responsive]="true" [loading]="tableLoading" [rowHover]="true"  [rows]="9" [showCurrentPageReport]="true"
            [paginator]="!tableLoading" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [filterDelay]="0" [globalFilterFields]="['doc_type','vendor_name','timestamp','doc_number']">
            <ng-template pTemplate="header">
                <tr>
                    <th width="20%" pSortableColumn="doc_type">Name<p-sortIcon field="doc_type"></p-sortIcon></th>
                    <th width="20%" pSortableColumn="vendor_name">Owner <p-sortIcon field="vendor_name"></p-sortIcon></th>
                    <th width="25%" pSortableColumn="timestamp">Modified Date <p-sortIcon field="timestamp"></p-sortIcon></th>
                    <th width="16%" pSortableColumn="doc_number">File Count <p-sortIcon field="doc_number"></p-sortIcon></th>
                    <th width="14%" class="text-center">Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr class="ui-selectable-row">
                    <td><span class="ui-column-title">Name</span>{{item.doc_type}}</td>
                    <td><span class="ui-column-title">Owner</span>{{item.vendor_name}}</td>
                    <td><span class="ui-column-title">Modified Date</span>{{item.modified_date}} </td>
                    <td><span class="ui-column-title">File Count</span>{{item.count}}</td> 
                    <td class="text-center">
                      <button class="btn btn_mrg" (click)="onViewDocList(item.doc_type)" pTooltip="View Details" tooltipPosition="bottom"><i class="fa fa-eye"></i></button>
                      <button class="btn btn_mrg" (click)="onDownloadDocList(item.doc_type)" pTooltip="Download File" tooltipPosition="bottom"><i class="fa fa-download"></i></button>
                      <!-- <button class="btn btn_mrg" pTooltip="Delete" tooltipPosition="bottom"><i class="fa fa-trash"></i></button> -->
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5" class="noDataFound">No customers found.</td>
                </tr>
            </ng-template>
        </p-table>  
          <!-- <table class="table">
            <thead>
              <tr>
                <th>Instance <i class="fa fa-sort"></i></th>
                <th>File Name <i class="fa fa-sort"></i></th>
                <th>Bill Number <i class="fa fa-sort"></i></th>
                <th>Modified Date <i class="fa fa-sort"></i></th>
                <th>Total Amount <i class="fa fa-sort"></i></th>
                <th>Actions <i class="fa fa-sort"></i></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Instance1</td>
                <td>Insurance.pdf</td>
                <td>1234561234</td>
                <td>22 Mar 2020, 12:22:04 PM</td>
                <td>321456</td>
                <td>
                  <button class="btn"><i class="fa fa-pencil"></i></button>
                  <button class="btn"><i class="fa fa-download"></i></button>
                  <button class="btn"><i class="fa fa-trash"></i></button>
                </td>
              </tr>
              <tr>
                <td>Instance2</td>
                <td>Health-Insurance.pdf</td>
                <td>5612344326</td>
                <td>20 Mar 2020, 11:45:21 AM</td>
                <td>544321</td>
                <td>
                  <button class="btn"><i class="fa fa-pencil"></i></button>
                  <button class="btn"><i class="fa fa-download"></i></button>
                  <button class="btn"><i class="fa fa-trash"></i></button>
                </td>
              </tr>
            </tbody>
          </table> -->
          <!-- <div class="card-bottom">
            <ul class="pagination pagination-sm">
              <li class="page-item"><a class="page-link" href="#">Prev</a></li>
              <li class="page-item"><a class="page-link active" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">Next</a></li>
            </ul>          
          </div> -->
        </div>
      </div>
    </div>
  </div>